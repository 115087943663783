<!--
 * @Description: 拆卸单
 * @Author: ChenXueLin
 * @Date: 2021-10-12 15:19:02
 * @LastEditTime: 2023-02-20 16:18:20
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <section class="edit-wrapper">
        <div class="edit-wrapper__body">
          <!-- 基本信息 start -->
          <template>
            <div class="primaryTitle">拆卸任务单</div>
            <div class="baseInfo-box">
              <div class="edit-title">基本信息</div>
              <el-form
                ref="baseInfoForm"
                class="editForm"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="baseInfoFormRules"
                :model="baseInfoForm"
              >
                <el-form-item label="客户名称" prop="corpId">
                  <e6-vr-select
                    v-model="baseInfoForm.corpId"
                    :data="corpList"
                    :disabled="queryId ? true : false"
                    placeholder="客户名称"
                    title="客户名称"
                    clearable
                    virtual
                    remote
                    :is-title="true"
                    @filterChange="handleLoadCorpFilter"
                    @change="handleChange"
                    :props="{
                      id: 'corpId',
                      label: 'corpName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="联系人" prop="linkmanrpid">
                  <div class="select-content">
                    <e6-vr-select
                      v-model="baseInfoForm.linkmanrpid"
                      :data="contactList"
                      placeholder="联系人"
                      title=""
                      :props="{
                        id: 'contactId',
                        label: 'contactName'
                      }"
                      clearable
                      @change="handleContact"
                      @clear="handleClear"
                    ></e6-vr-select>
                    <el-button
                      type="text"
                      style="margin-left: 10px"
                      @click="addConcat"
                    >
                      添加联系人
                    </el-button>
                  </div>
                </el-form-item>

                <el-form-item label="联系电话" prop="contactPhone">
                  <el-input
                    placeholder="联系电话"
                    v-model="baseInfoForm.contactPhone"
                  ></el-input>
                </el-form-item>
                <el-form-item label="联系地址" prop="defaultCity">
                  <e6-vr-select
                    style="width:300px"
                    v-model="baseInfoForm.defaultCity"
                    :data="addressList"
                    placeholder="联系地址"
                    title="联系地址"
                    virtual
                    clearable
                    :is-title="true"
                    remote
                    @filterChange="handleLoadAddressFilter"
                    @change="handleCitySelect"
                    :props="{
                      id: 'fullName',
                      label: 'fullName'
                    }"
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="详细地址" prop="insAddress">
                  <el-input
                    placeholder="详细地址"
                    v-model="baseInfoForm.insAddress"
                  ></el-input>
                </el-form-item>
                <el-form-item label="要求完成时间" prop="expecttime">
                  <el-date-picker
                    v-model="baseInfoForm.expecttime"
                    type="datetime"
                    placeholder="选择日期"
                    value-format="timestamp"
                    popper-class="special"
                    :picker-options="pickerOptions"
                  ></el-date-picker>
                </el-form-item>
                <el-form-item label="备注" prop="remark">
                  <el-input
                    v-model="baseInfoForm.remark"
                    placeholder="请输入备注"
                    type="textarea"
                    :rows="3"
                    style="width: 500px"
                    maxlength="100"
                  ></el-input>
                </el-form-item>
              </el-form>
            </div>
            <div class="select-form-box">
              <el-form
                ref="selectForm"
                class="editForm"
                label-position="right"
                :inline="true"
                label-width="120px"
                :rules="selectFormRules"
                :model="selectForm"
              >
                <el-form-item label="是否需要报停" prop="isStop">
                  <e6-vr-select
                    v-model="selectForm.isStop"
                    :data="isStopList"
                    placeholder="是否需要报停"
                    title="是否需要报停"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
                <el-form-item label="是否回收设备" prop="isRecovery">
                  <e6-vr-select
                    v-model="selectForm.isRecovery"
                    :data="isStopList"
                    placeholder="是否回收设备"
                    title="是否回收设备"
                    clearable
                  ></e6-vr-select>
                </el-form-item>
              </el-form>
            </div>
          </template>
          <!-- 基本信息 end -->
          <!-- 任务项设置   start -->
          <template>
            <div class="edit-title">任务项设置</div>
            <div class="edit-content" style="margin-bottom: 20px">
              <div class="associated-info">
                <div class="right-button">
                  <el-button type="primary" @click="addScene">
                    添加任务项
                  </el-button>
                  <el-button type="primary" @click="handleDelete">
                    删除任务项
                  </el-button>
                  <!-- <el-button type="primary">
                    导入任务项
                  </el-button> -->
                </div>
              </div>
              <el-table
                style="width:100%;"
                ref="multipleTable"
                :data="tableData"
                highlight-current-row
                @selection-change="handleSelectionChange"
              >
                <el-table-column type="selection" width="50"></el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="sceneName"
                  label="场景名称"
                  fixed
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show">{{ row.sceneName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="secondClassName"
                  label="安装组合"
                  align="center"
                  header-align="center"
                >
                  <template slot-scope="{ row }">
                    <span v-if="row.show2">{{ row.secondClassName }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="thirdClassName"
                  label="已安装商品"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="firstClassName"
                  label="商品分类"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="equipCode"
                  label="设备编号"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
                <el-table-column
                  show-overflow-tooltip
                  prop="virtualTypeName"
                  label="类型"
                  align="center"
                  header-align="center"
                >
                </el-table-column>
              </el-table>
            </div>
          </template>
        </div>
        <div class="edit-wrapper__footer">
          <el-button class="cancel" @click="handleGoBack">取消</el-button>
          <el-button type="primary" @click="submit">确定</el-button>
        </div>
      </section>
      <!-- 选择场景 -->
      <select-scene
        :selectSceneDialog="selectSceneDialog"
        @handleClose="handleClose"
        @getData="getData"
        :corpId="baseInfoForm.corpId"
      ></select-scene>
      <!-- 添加联系人弹框 -->
      <add-concat-dialog
        :addContactVisible="addContactVisible"
        @handleClose="handleClose"
        @updateList="updateList"
        :isNeedReq="true"
        :corpId="baseInfoForm.corpId"
        :corpName="baseInfoForm.corpName"
      ></add-concat-dialog>
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import SelectScene from "@/components/workOrderManage/selectScene.vue";
import AddConcatDialog from "@/components/addConcatDialog.vue";
import addTask from "@/mixins/addTask";
import { createRemoveOrder } from "@/api";
import { printError } from "@/utils/util";
import { telAndMobileValid } from "@/utils/validate";
export default {
  name: "addRemoveOrder",
  components: { SelectScene, AddConcatDialog },
  data() {
    return {
      loading: false,
      workSecondClass: "12",
      dialogLoading: false,
      isConfirm: 1,
      baseInfoForm: {
        corpId: "", //公司id
        insAddress: "", //详细地址
        linkmanrpid: "", //联系人id
        contactPhone: "", //电话
        defaultCity: "", //默认选中地址
        expecttime: ""
      }, //基本信息
      baseInfoFormRules: {
        defaultCity: [
          {
            required: true,
            message: "请选择地址",
            trigger: ["blur", "change"]
          }
        ],
        insAddress: [
          {
            required: true,
            message: "请输入详细地址",
            trigger: ["blur", "change"]
          }
        ],
        corpId: [
          {
            required: true,
            message: "请选择客户名称",
            trigger: ["blur", "change"]
          }
        ],
        linkmanrpid: [
          {
            required: true,
            message: "请选择联系人名称",
            trigger: ["blur", "change"]
          }
        ],
        expecttime: [
          {
            required: true,
            message: "请选择解决时间",
            trigger: ["blur", "change"]
          }
        ],
        contactPhone: telAndMobileValid.required({
          requiredMsg: "请输入手机号码",
          message: "号码格式不正确",
          trigger: ["blur", "change"]
        })
      },
      selectForm: {
        isStop: "", //是否报停
        isRecovery: "" //是否回收
      },
      selectFormRules: {
        isStop: {
          required: true,
          message: "请选择是否报停",
          trigger: ["blur", "change"]
        },
        isRecovery: {
          required: true,
          message: "请选择是否回收",
          trigger: ["blur", "change"]
        }
      },
      isStopList: [
        {
          id: 0,
          label: "否"
        },
        {
          id: 1,
          label: "是"
        }
      ], //是否下拉框数据
      addContactVisible: false
    };
  },
  mixins: [base, addTask],
  created() {},
  watch: {},
  methods: {
    //客户下拉框处理逻辑
    handleLoadCorpFilter: _.debounce(async function(val) {
      if (val) {
        this.baseInfoForm.corpId = "";
        this.loadCorpData(val);
      }
    }, 300),
    //添加联系人之后更新下拉框
    updateList() {
      this.getContactList();
    },
    //点击确定
    submit() {
      this.$refs.baseInfoForm.validate(valid1 => {
        this.$refs.selectForm.validate(valid2 => {
          if (valid1 && valid2) {
            // if (!this.baseInfoForm.insCountyName) {
            //   this.$message.warning("区县不能为空");
            //   return;
            // }
            this.baseInfoForm.contactName = this.filterSelect(
              this.contactList,
              "contactId",
              this.baseInfoForm.linkmanrpid
            ).contactName;

            if (!this.tableData.length) {
              this.$message.warning("请至少选择一个任务项");
              return;
            }
            this.checkCreateTaskReq();
          }
        });
      });
    },

    //创建维修单请求
    async createTaskReq() {
      try {
        this.loading = true;
        let res = await createRemoveOrder({
          ...this.baseInfoForm,
          ...this.selectForm,
          isConfirm: this.isConfirm,
          taskItemReqVOList: this.tableData
        });
        if (res.code == "OK") {
          this.$message.success("创建成功");
          this.closeTag(this.$route);
          this.routerPush({
            name: "taskListManage/allTaskList",
            params: {
              refresh: true
            }
          });
        }
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>
<style lang="scss" scoped>
.edit-content {
  padding: 20px 20px 2px;
  .e6-vr-select {
    width: 100%;
  }
}

// .edit-title {
//   display: flex;
//   justify-content: space-between;
//   align-items: center;
//   padding-right: 15px;
//   .operate-action {
//     height: 38px;
//   }
// }
.associated-info {
  display: flex;
  margin-bottom: 20px;
  justify-content: space-between;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}
/deep/.edit-address {
  .el-form-item__label {
    &::before {
      content: "*";
      color: #f56c6c;
      margin-right: 4px;
    }
  }
}
</style>
